import { template as template_c98b8fa701154c39b0f0f10b5a86a3c4 } from "@ember/template-compiler";
const WelcomeHeader = template_c98b8fa701154c39b0f0f10b5a86a3c4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
